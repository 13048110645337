<template>
  <svg
    width="22"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="none"
      d="M7 3H1v14h14v-6"
    />
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M7 3H1v14h14v-6M9 9l7-7m-5-1h6v6"
    />
  </svg>
</template>
